import React from "react";
import { Helmet } from "react-helmet";
import PageWrapper from "../components/PageWrapper";

const Contact = () => { 
  return (
    <> 
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,
          version:"home-version",
          footerStyle: "digma",
        }}>      
        <Helmet
    bodyAttributes={{
        class: 'no-head'
    }}>
        <script
  dangerouslySetInnerHTML={{
    __html: `

    document.addEventListener("mouseleave", (event) => { 
          var element = document.getElementById("pop");
 
      if (event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) {  
      element.classList.add("active");
      }   
      });
    `,
  }}
/>
    </Helmet>
      <section className="containerTest">
        <div className="tiktok">
          <h1>Look 1 / 4</h1>
          <div className="digma-image" data-imageid="E26878BE-9D87-4E1C-80A9-008755B408BB"><img className="w-100" alt="Farm Yard Animals" src="https://digmacustomers.blob.core.windows.net/4fe433c9-fb0f-47d4-9f59-37f1909ec6b8/7030c2ac-870a-4886-ad0c-fa5cc694a130.jpg" /></div>
        </div>

        <div className="tiktok">
          <h1>Look 2 / 4</h1>          
          <div className="digma-image" data-imageid="AA70A7E4-E058-4208-9EDF-19B5A4326DF2"><img className="w-100" alt="Recycled Sky Oxford Over Shirt" src="https://digmacustomers.blob.core.windows.net/30bfc815-2a7a-4782-91fb-ee941c013506/f8729d8b-8cd5-4be8-8ca4-99a7ecea4937.jpg" /></div>
        </div>
        <div className="tiktok">
          <h1>Look 3 / 4</h1>
          <div className="digma" data-projectid="78D7CF0E-79C6-4418-ABBC-4310ECF316B9"></div>
        </div>
        <div className="tiktok">
          <h1>Look 4 / 4</h1>          
          <div className="digma" data-projectid="302020E4-4671-4C12-A0B4-8AA025035E12"></div>
        </div>        
     </section>
     <div className="look-foot">
     Spring 2020 Read-To-Wear
      </div>
     </PageWrapper>
    </>
  );
};
export default Contact;
